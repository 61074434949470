
<template>
<div class="content-wrap" >
  <Nav></Nav>
  <HeaderStyle :title="title" :describe="describe" :backgroundUrl="backgroundUrl"></HeaderStyle>
  <div class="content-box-p">
         <div class="content-box" v-for="(item,index) in mews" :key="index" @click="goDetail(item.id)">
    <!-- 新闻列表 -->
      <div class="l-box">
          <img :src="item.url" alt="">
      </div>
      <div class="r-box">
          <div class="title">{{item.title}}</div>
          <p class="content">{{item.content}}</p>
          <div class="detail" @click="goDetail(item.id)">查看详情</div>
      </div>
    </div>
  </div>

    <div class="block">
      <el-pagination
      background
      @current-change="currentChange"
      :page-size="pageSize"
      layout="prev, pager, next,jumper"
      :total="total">
      </el-pagination>
  </div>
</div>
 
</template>

<script>
import AppFooter from '@/components/layer/AppFooter'
import SerAsk from '@/components/layer/SerAsk'
import HeaderStyle from '@/components/commom/HeaderStyle'
import Nav from "@/components/layer/HeaderNav";
import api from "@/api/index";
import { baseURL} from '@/config'
export default {
  name: 'new',
   components: {
    AppFooter,
    SerAsk,
    HeaderStyle,
    Nav
  },
  metaInfo:{
      title: '枫调理顺-社会治理与纠纷解决方案商', //设置页面title
      meta: [
        {                 //设置页面meta
        name: 'keyWords',
        content: '消费纠纷,监管,市场监管,预付,预付款,提前消费,预付监管,充值资金,办卡,枫调理顺,至信链,腾讯,存证,司法存证,版权存证,侵权监测,侵权取证,NFT,版权保护,金融存证,区块链,智能合约,上链,上链存证,培训,调解,调解员,职业培训'
        }
      ]
  },
  data() {
    return {
      	pageNum: 1, // 当前页码
				pageSize: 5, // 总页数 1 5 
      // articleIds:[],// 文章id 详情接口
      total:0,
      mews:[
        {
          url: require("@/assets/comDynamics/01.png"),
          title:"金融审判信息化研讨会在深举办，“至信链”助力金融纠纷化解",
          content:"深圳市中级人民法院积极推动金融审判与信息技术的深度融合，推出深圳法院金融类案智审系统，助力金融类案全流程快速解决。"
        },
        {
          url: require("@/assets/comDynamics/01.png"),
          title:"金融审判信息化研讨会在深举办，“至信链”助力金融纠纷化解",
          content:"深圳市中级人民法院积极推动金融审判与信息技术的深度融合，推出深圳法院金融类案智审系统，助力金融类案全流程快速解决。"
        },{
          url: require("@/assets/comDynamics/01.png"),
          title:"金融审判信息化研讨会在深举办，“至信链”助力金融纠纷化解",
          content:"深圳市中级人民法院积极推动金融审判与信息技术的深度融合，推出深圳法院金融类案智审系统，助力金融类案全流程快速解决。"
        },{
          url: require("@/assets/comDynamics/01.png"),
          title:"金融审判信息化研讨会在深举办，“至信链”助力金融纠纷化解",
          content:"深圳市中级人民法院积极推动金融审判与信息技术的深度融合，推出深圳法院金融类案智审系统，助力金融类案全流程快速解决。"
        }
        ],
        currentPage: 5,
        title:"公司动态",
        backgroundUrl:"backgroundUrl",
        describe:"聚合公司新闻、媒体报道，社交媒体活动及更多信息"
    }
  },
	mounted() {
		this.getList();
		// this.getInfo();
    this.modifyText()
	},
  methods: {
    modifyText(){
      document.getElementsByClassName("el-pagination__jump")[0].childNodes[0].nodeValue = "跳至";
    },
    // 路由跳转
    goDetail(id){
      this.$router.push({
				path: "/DynamicDetail",
				query: { id:id },
			});
    },
    currentChange(val){
        this.pageNum = val
        this.getList()
    },
    // 动态新闻
   	getList() {
      let categoryId = 12 //  临时请求公告的id 1 12
			api.news.getList({
				articleId: null,
				categoryId: categoryId,
				pageNum: this.pageNum,
				pageSize: this.pageSize, // 1 5 
			}).then((res) => {
        console.log("res",res)
        if(res.code == 0){
          let pageInfo = res.data.pageInfo
          this.total = pageInfo.total          
          if(pageInfo && pageInfo.list && pageInfo.list.length){
             pageInfo.list.forEach(item => {
                item.url = baseURL +  item.coverImage
                item.title = item.title
                item.content = item.description
             });
             this.mews = pageInfo.list
          }
         
        }
      });
		},
    // 获取当前5条数据的详情
    // getAllInfo(){
    //   let articleDetails =[]
    //   this.articleIds.forEach(item=>{
    //     	api.news.getInfo({ articleId: item }).then((res) => {
    //         console.log("res",res)
    //           if(res){

    //           }
    //       });
    //   })
    // },
    // 新闻详情
		// getInfo() {
		// 	api.news.getInfo({ articleId: 9164 }).then(() => {});
		// },
      handleSizeChange(val) {
        console.log(`每页 ${val} 条`);
      },
      handleCurrentChange(val) {
        console.log(`当前页: ${val}`);
      }
    },
}
</script>
<style lang="scss" scoped>
.content-box-p{
  padding-top:0.50rem;
   width:14.32rem;
    padding:0.99rem 1.20rem  0rem 1.20rem;
  box-sizing: border-box;
  margin:0rem auto;
 
}
.content-box{
  display: flex;
  // width:14.32rem;
  margin: 0 auto;
  border-bottom:0.01rem solid #BFC7D1;
  padding-bottom: 0.42rem;
  padding-top:0.40rem;
}
  .l-box{
    width:3.50rem;
    height:1.97rem;
    margin-right:0.89rem;
    border: 1px solid #BFC7D1;
    img{
    width:3.50rem;
    height:1.97rem;
    object-fit: cover;
    }
  }
  .r-box{
    box-sizing: border-box;
    width:7.63rem;
    height:1.92rem;
    letter-spacing: 0;
    .title{
      font-family: PingFangSC-Semibold;
      width:7.63rem;
      font-size: 0.28rem;
      color: #000;
      line-height: 0.28rem;
      text-overflow: -o-ellipsis-lastline;
      overflow: hidden;
      text-overflow: ellipsis;
      white-space: nowrap;
      cursor: pointer;
    }
    .title:hover{
       color: #136EFF;
    }
    .content{
      font-family: PingFangSC-Regular;
      width: 7.21rem;
      font-size: 0.18rem;
      color: #8C99AA;
      line-height: 0.32rem;
      padding-top:0.23rem;
      text-overflow: -o-ellipsis-lastline;
      overflow: hidden;				//溢出内容隐藏
      text-overflow: ellipsis;		//文本溢出部分用省略号表示
      display: -webkit-box;			//特别显示模式
      -webkit-line-clamp: 2;			//行数
      line-clamp: 2;					
      -webkit-box-orient: vertical;	//盒子中内容竖直排列

    }
    .detail{
      font-size: 0.18rem;
      color: #136EFF;
      margin-top:0.33rem;
      cursor: pointer;
      width: 1.04rem;
      height: 0.38rem;
      border:1px solid #136EFF;
      display: flex;
      align-items: center;
      justify-content: center;
    }
  }
  .block{
    text-align: center;
    padding:0.60rem;
    /deep/ .el-pagination.is-background .el-pager li:not(.disabled).active{
      background: #136EFF;
    }
  }
  .backgroundUrl{
    background-image:url("../../assets/comDynamics/dongtai.png");
    background-size:100% 100%;
}
</style>